import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  Dialog,
  Select,
  Textarea,
  Typography,
  Snackbar,
  ISnackbarProps,
} from "@ntpkunity/controls";
import { DialogContentWrap } from "./manual-status-change-dialog.style";
import { Stack } from "@components/stack";
import { StatusButton } from "@components/status-buttons";
import { ManualStatusChangeDialogProps } from "./manual-status-change-dialog.props";
import { useChangeProposalStatus } from "@hooks";
import { useState } from "react";
import { PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP } from "@helpers/const";
import { CommentCategory, OrderStatus } from "@helpers/enum";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";

export const ManualStatusChangeDialog = ({
  open,
  closeDialog,
  dialogData,
  openConfirmDialog,
}: ManualStatusChangeDialogProps) => {
  if (!open) {
    return <></>;
  }
  const queryClient = useQueryClient();
  const currentStatus = dialogData.status;
  const allowedStatuses: OrderStatus[] =
    PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP[currentStatus];

  const theme = useTheme();
  const [selectedNextStatus, setSelectedNextStatus] = useState<OrderStatus>();
  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    message: string;
    variant?: ISnackbarProps["variant"];
  }>({
    open: false,
    message: "",
    variant: null,
  });

  const [commentText, setCommentText] = useState<string>(null);
  const defaultCategory = CommentCategory.GENERAL;
  const resetSnackbar = () =>
    setSnackbarState({ open: false, variant: null, message: "" });

  const saveProposalStatus = async () => {
    if (!selectedNextStatus || !allowedStatuses.includes(selectedNextStatus)) {
      setSnackbarState({
        message: "Please select appropriate new status",
        open: true,
        variant: "error",
      });
      return;
    }

    openConfirmDialog({
      identifier: dialogData.identifier,
      status: currentStatus,
      nextStatus: selectedNextStatus,
      referenceNumber: dialogData.identifier,
      name: dialogData.name,
      proposalComment: {
        comment: commentText,
        category: defaultCategory,
      },
    });

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_DIARY_COMMENTS, dialogData.identifier],
    });
  };

  return (
    <Dialog
      theme={theme}
      size="sm"
      open={open}
      title="Manually Change Status"
      onCloseDialog={closeDialog}
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={closeDialog}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Save"
            onClick={saveProposalStatus}
          />
        </>
      }
    >
      <DialogContentWrap theme={theme} className="dialog-content-wrap">
        <Stack
          bgLight
          paddingMd={2}
          paddingXs={2}
          title="Current Status"
          actionArea={<StatusButton status={currentStatus} bgLight />}
        />
        <Box theme={theme} mt={3}>
          <Typography theme={theme} variant="subtitle1" component={"p"}>
            Add a New Status
          </Typography>
          <Box
            theme={theme}
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={2}
            mt={3}
          >
            {allowedStatuses.map((allowedStatus) => (
              <StatusButton
                key={allowedStatus}
                status={allowedStatus}
                selected={selectedNextStatus === allowedStatus}
                onClick={() => setSelectedNextStatus(allowedStatus)}
              />
            ))}
          </Box>
        </Box>
        <Box theme={theme} mt={3} className="comments-area" pt={3}>
          <Box theme={theme} display={"flex"} alignItems={"center"}>
            <Typography
              theme={theme}
              variant="h4"
              component={"h4"}
              flexGrow={1}
            >
              Add Comments
            </Typography>
            <Box theme={theme} className="custom-dropdown" flexShrink={0}>
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component={"span"}
                display={"block"}
              >
                Catagory:
              </Typography>
              <Select
                theme={theme}
                disablePortal={false}
                items={[{ text: defaultCategory, value: defaultCategory }]}
                value={defaultCategory}
              />
            </Box>
          </Box>
          <Box theme={theme} mt={3}>
            <Textarea
              theme={theme}
              rows={3}
              placeholder="Type here..."
              label="Add a Comment"
              fullWidth
              value={commentText}
              onChange={(value) => setCommentText(value)}
              type={""}
              id={""}
            />
          </Box>
        </Box>
        <Snackbar
          {...snackbarState}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          onClose={resetSnackbar}
          action={true}
        />
      </DialogContentWrap>
    </Dialog>
  );
};
