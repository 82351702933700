import { WorkQueueTabs, UserPermissions } from "@helpers/enum";
import { useHasPermissions } from "@ntpkunity/controls-ums";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "@ntpkunity/controls";
import { APP_ROUTES, BROKER_BASE_ROUTE } from "@helpers/const";

export const useNavPermissions = () => {
  const permissions = {
    dashboard: {
      root: useHasPermissions(UserPermissions.DASHBOARD),
      introducerDashboard: useHasPermissions(
        UserPermissions.INTRODUCER_DASHBOARD
      ),
      lenderDashboard: useHasPermissions(UserPermissions.LENDER_DASHBOARD),
    },
    proposalAndQuotation: {
      root: useHasPermissions(UserPermissions.PROPOSAL_AND_QUOTATIONS),
      createQuotation: useHasPermissions(UserPermissions.CREATE_NEW_QUOTATION),
      createProposal: useHasPermissions(UserPermissions.CREATE_NEW_PROPOSAL),
      workqueue: useHasPermissions(UserPermissions.WORKQUEUE),
    },
    adminOptions: {
      root: useHasPermissions(UserPermissions.ADMIN_OPTIONS),
      configurations: useHasPermissions(
        UserPermissions.ADMIN_OPTION_CONFIGURATIONS
      ),
      setups: useHasPermissions(UserPermissions.ADMIN_OPTION_SETUPS),
    },
    userManagement: {
      root: useHasPermissions(UserPermissions.USER_MANAGEMENT),
    },
  };
  return permissions;
};

const sanitizeSubOptions = (
  options: { label: string; onClick: () => void; selected: boolean }[]
) => {
  return options.filter(Boolean);
};

const useGetExpandProp = () => {
  const location = useLocation();
  const pathName = location.pathname;
  return (routes: string[]) => {
    if (pathName === BROKER_BASE_ROUTE) return false;
    return routes.some(
      (route) => route.includes(pathName) || pathName.includes(route)
    );
  };
};

export const useNavItems = ({
  handleRootOptionsClick,
}: {
  handleRootOptionsClick: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const permissions = useNavPermissions();
  const { dashboard, proposalAndQuotation, adminOptions, userManagement } =
    permissions;

  const menuItems = [];

  if (dashboard.root) {
    const itemTree = {
      icon: <Icon name="DashboardIcon" />,
      label: "Dashboard",
      expanded: true,
      onClick: handleRootOptionsClick,
      selected: false,
      subOptions: sanitizeSubOptions([
        dashboard.introducerDashboard && {
          label: "Introducer Dashboard",
          onClick: () => navigate(APP_ROUTES.ANALYTICS),
          selected: APP_ROUTES.ANALYTICS === pathName,
        },
        dashboard.lenderDashboard && {
          label: "Lender Dashboard",
          onClick: () => navigate(APP_ROUTES.LENDER_DASHBOARD),
          selected: APP_ROUTES.LENDER_DASHBOARD === pathName,
        },
      ]),
    };
    menuItems.push(itemTree);
  }

  if (proposalAndQuotation.root) {
    const itemTree = {
      icon: <Icon name="PaperEditIcon" />,
      label: "Proposals & Quotations",
      onClick: handleRootOptionsClick,
      selected: false,
      expanded:true,
      subOptions: sanitizeSubOptions([
        proposalAndQuotation.createQuotation && {
          label: "Create New Quotation",
          onClick: () => navigate(APP_ROUTES.QUOTATION),
          selected: pathName.includes(APP_ROUTES.QUOTATION),
        },
        proposalAndQuotation.createProposal && {
          label: "Create New Proposal",
          onClick: () => navigate(APP_ROUTES.PROPOSAL),
          selected: pathName.includes(APP_ROUTES.PROPOSAL),
        },
        proposalAndQuotation.workqueue && {
          label: "Workqueue",
          onClick: () =>
            navigate(
              `${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`
            ),
          selected: pathName.includes(APP_ROUTES.WORKQUEUE),
        },
      ]),
    };
    menuItems.push(itemTree);
  }

  if (adminOptions.root) {
    const itemTree = {
      icon: <Icon name="PaperUserIcon" />,
      label: "Admin Options",
      onClick: handleRootOptionsClick,
      selected: false,
      expanded: true,
      subOptions: sanitizeSubOptions([
        adminOptions.configurations && {
          label: "Configurations",
          onClick: () => navigate(APP_ROUTES.FINANCIAL_CONFIGURATIONS),
          selected: pathName.includes(APP_ROUTES.FINANCIAL_CONFIGURATIONS),
        },
        adminOptions.setups && {
          label: "Setups",
          onClick: () => window.open(process.env.INDEX_CLIENT_URL, "_blank"),
          selected: false,
        },
      ]),
    };
    menuItems.push(itemTree);
  }

  if (userManagement.root) {
    const itemTree = {
      icon: <Icon name="UserManagementIcon" />,
      label: "User Management",
      onClick: () => navigate(APP_ROUTES.USER_MANAGEMENT),
      selected: pathName.includes(APP_ROUTES.USER_MANAGEMENT),
      expanded: false,
      subOptions: [],
    };
    menuItems.push(itemTree);
  }

  return menuItems;
};
