import {
  Box,
  Button,
  DataTable,
  Icon,
  Menu,
  Pagination,
  Typography,
} from "@ntpkunity/controls";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { SignatoriesTableWrap } from "./add-signatories.style";
import { Stack } from "@components/stack";
import { DataTableWrap } from "@styles/data-table-wrap";
import AddSignatoriesDialog from "./add-signatories-dialog.component";
import {
  SignatoryResponse,
  TSignatoriesSearchParams,
} from "@_types/complianceDocuments";
import { SignatoryRowOptions } from "@helpers/enum";
import SignatoriesFilter from "./signatories-filter.component";
import { useDialogState } from "@hooks/useDialogState";
import { useSnackbarContext } from "@contexts/snackbar";
import { useQueryClient } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { deleteSignatory } from "@services/dms.service";
import { TSignatoryForm } from "@_types/forms/complianceDocument";

const SignatoriesTable = ({
  signatories,
  commonFilterParams,
  referenceId,
  isGeneratedDocument,
}) => {
  const { setSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();
  const {
    dialogOpen: signatoryDialogOpened,
    dialogData: signatoryDialogData,
    openDialog: openSignatoryDialog,
    closeDialog: closeSignatoryDialog,
  } = useDialogState<TSignatoryForm>();

  const handleDelete = async (identifier: string) => {
    try {
      await deleteSignatory(identifier);
      setSnackbar(
        {
          message: "Signatory Deleted!",
          open: true,
          variant: null,
        },
        10000
      );
      queryClient.invalidateQueries([QUERY_KEYS.SIGNATORIES]);
    } catch (e) {
      setSnackbar(
        {
          message: "Deletion Failed!",
          open: true,
          variant: null,
        },
        10000
      );
    }
  };

  const handleOptionClick = (_, key: SignatoryRowOptions, signatory: any) => {
    if (key === SignatoryRowOptions.VIEW_EDIT) {
      const address = signatory.stakeholder_address;
      const nameSplit = signatory.name.split(" ");
      openSignatoryDialog({
        identifier: signatory.identifier,
        firstName: nameSplit[0],
        lastName: nameSplit[1],
        email: signatory.email,
        position: signatory.position,
        contactNumber: signatory.contact_number,
        addressId: signatory.address_id,
        address: {
          addressLine1: address?.address_line_1,
          addressLine2: address?.address_line_2,
          city: address?.city,
          county: address?.county,
          identifier: address?.identifier,
          zipCode: address?.zip_code,
        },
      });
      return;
    }
    if (key === SignatoryRowOptions.DELETE) {
      handleDelete(signatory);
      return;
    }
  };

  const theme = useTheme();
  return (
    <SignatoriesTableWrap theme={theme} className="signatories-table-wrap">
      <Stack bgLight paddingMd={2} paddingXs={2}>
        <DataTableWrap theme={theme} className="table-pagination-button">
          <Box theme={theme} className="scroll">
            <Box className="scroll-hide spr-border" theme={theme} />
          </Box>
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow className="has-sorting">
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
                {commonFilterParams?.searchParams && (
                  <TableRow className="filters-row">
                    <TableCell>
                      <SignatoriesFilter
                        {...commonFilterParams}
                        searchKey="name"
                      />
                    </TableCell>
                    <TableCell>
                      <SignatoriesFilter
                        {...commonFilterParams}
                        searchKey="name"
                      />
                    </TableCell>
                    <TableCell>
                      <SignatoriesFilter
                        {...commonFilterParams}
                        searchKey="email"
                      />
                    </TableCell>
                    <TableCell>
                      <SignatoriesFilter
                        {...commonFilterParams}
                        searchKey="position"
                      />
                    </TableCell>
                    <TableCell className="action-cell fixed-cell" />
                  </TableRow>
                )}
              </>
            }
            tbodyChildren={
              <>
                {signatories?.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        display="block"
                        textAlign="center"
                      >
                        No Signatories found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  signatories?.map((signatory: SignatoryResponse, key) => (
                    <TableRow key={key} className="child-tr">
                      <TableCell>{signatory.name?.split(" ")[0]}</TableCell>
                      <TableCell>{signatory.name?.split(" ")[1]}</TableCell>
                      <TableCell>{signatory?.email}</TableCell>
                      <TableCell>{signatory?.position}</TableCell>

                      <TableCell className="action-cell fixed-cell">
                        {!isGeneratedDocument && (
                          <Menu
                            theme={theme}
                            options={[
                              {
                                optionText: "View/Edit",
                                optionkey: "view-edit",
                                optionValue: signatory,
                              },
                              {
                                optionText: "Delete",
                                optionkey: "delete",
                                optionValue: signatory.identifier,
                              },
                            ]}
                            handleOptionClick={handleOptionClick}
                            render={(cb) => (
                              <Button
                                defaultBtn
                                theme={theme}
                                iconText={<Icon name="MoreIcon" />}
                                onClick={cb}
                              ></Button>
                            )}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </>
            }
          />
          <Pagination theme={theme} />
        </DataTableWrap>
        {!isGeneratedDocument && (
          <Box theme={theme} mt={2}>
            <Button
              theme={theme}
              fullWidth
              secondary
              text={"Add New Signatory"}
              onClick={() => openSignatoryDialog()}
            />
          </Box>
        )}
      </Stack>
      <AddSignatoriesDialog
        open={signatoryDialogOpened}
        onClose={closeSignatoryDialog}
        data={signatoryDialogData}
        referenceId={referenceId}
      />
    </SignatoriesTableWrap>
  );
};

export default SignatoriesTable;
