import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";
import { getUserSettings, removeDeletedEntities } from "@helpers/utils";
import { TInputDef } from "@components/proposal/proposal-details/types";
import { useMemo } from "react";
import { useProposalDetailsSetups } from "@hooks/proposal";
import useGetAllLenders from "@hooks/queries/useGetAllLenders";
import useGetAllActiveDealers from "@hooks/queries/useGetAllDealers";
import { Roles, UserPermissions } from "@helpers/enum";
import { useGetAllPermissions } from "@ntpkunity/controls-ums";

type DealerOption = {
  label: string;
};

const useProposalDetailsDefs = () => {
  const { setValue, watch } = useFormContext<TProposalForm>();
  const { permissions } = useGetAllPermissions();
  const productType = watch("proposalDetails.productType");
  const financeType = watch("proposalDetails.financeType");
  const dealerId = watch("proposalDetails.dealerId");
  const { productTypes, financeTypes } = useProposalDetailsSetups();
  const { data: lenderData } = useGetAllLenders();
  const { data: dealerData } = useGetAllActiveDealers();
  const hasLenderNamePermission = permissions.some(
    (permission) => permission.name === UserPermissions.LENDER_NAME
  );
  const hasDealerNamePermission = permissions.some(
    (permission) => permission.name === UserPermissions.DEALER_NAME
  );
  const userSettings = getUserSettings();
  const userRoleName = userSettings?.role?.name;
  const disableDealerName = !!(
    dealerId && [Roles.BROKER_ADMIN, Roles.BROKER_USER].includes(userRoleName)
  );
  const getInputDefs = (): TInputDef[] => {
    const inputDefs: TInputDef[] = [
      {
        name: "proposalDetails.name",
        formElement: "Input",
        placeHolder: "Proposal Name",
        label: "Proposal Name",
        type: "text",
        sanitizeChange: (value) => {
          return value.replace(/[^a-zA-Z0-9 ]/g, "");
        },
      },
    ];
    if (hasLenderNamePermission) {
      inputDefs.push({
        name: "proposalDetails.lenderName",
        formElement: "AutoComplete",
        label: "Lender Name",
        items: lenderData?.map((lender: any) => ({
          label: lender.name,
        })),
        getOptionLabel: (option: DealerOption) => option.label,
        onChange: (_, value: any) => {
          setValue("proposalDetails.lenderName", value?.label);
        },
        renderOption: (props, option: DealerOption) => (
          <li {...props} key={option.label}>
            <span>{option.label}</span>
          </li>
        ),
      });
    }

    if (hasDealerNamePermission) {
      inputDefs.push({
        name: "proposalDetails.dealerName",
        formElement: "AutoComplete",
        label: "Dealer Name (Optional)",
        items: dealerData?.map((dealer: any) => ({
          label: dealer.dealer_name,
        })),
        disabled: disableDealerName,
        getOptionLabel: (option: DealerOption) => option.label,
        onChange: (_, value: any) => {
          setValue("proposalDetails.dealerName", value?.label);
        },
        renderOption: (props, option: DealerOption) => (
          <li {...props} key={option.label}>
            <span>{option.label}</span>
          </li>
        ),
      });
    }

    inputDefs.push(
      {
        name: "proposalDetails.financeType",
        formElement: "Select",
        label: "Finance Type",
        items: removeDeletedEntities(financeType, financeTypes)?.map(
          (financeType) => ({
            text: financeType.name,
            value: financeType.code,
          })
        ),
      },
      {
        name: "proposalDetails.productType",
        formElement: "Select",
        label: "Product Type",
        items: removeDeletedEntities(productType, productTypes)?.map(
          (productType) => ({
            text: productType.name,
            value: productType.code,
          })
        ),
      }
    );

    return inputDefs;
  };

  return useMemo(getInputDefs, [setValue, productTypes, financeTypes]);
};

export default useProposalDetailsDefs;
