import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getAssetSubTypes } from "@services/index.service";
import { AssetSubTypesSetupRes } from "@_types/setups";
import useGetCompanyByTenantId from "./useGetCompanyProfile";

const useGetAssetSubTypes = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, AssetSubTypesSetupRes>({
    queryKey: [QUERY_KEYS.GET_ASSET_SUB_TYPES, companyId],
    queryFn: () => getAssetSubTypes(companyId),
    enabled: !!companyId,
  });
};

export default useGetAssetSubTypes;
