import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getDiaryComments } from "@services/dms.service";

const useGetDiaryComments = (orderReferenceId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_DIARY_COMMENTS, orderReferenceId],
    queryFn: () => getDiaryComments(orderReferenceId),
    enabled: !!orderReferenceId,
  });
};

export default useGetDiaryComments;
