import "react";
import {
  AddressLayout,
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  Select,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { DialogContentContainer } from "./add-signatories.style";
import { GB_COUNTRY } from "@helpers/const";
import { useForm, Controller } from "react-hook-form";
import { TSignatoryForm } from "@_types/forms/complianceDocument";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { editSignatory, saveSignatory } from "@services/dms.service";
import { useQueryClient } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { useSnackbarContext } from "@contexts/snackbar";

type TAddressFromControl = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  zip_code: string;
  county: string;
};

const AddSignatoriesDialog = ({ open, onClose, data, referenceId }) => {
  if (!open) {
    return <></>;
  }
  const theme = useTheme();
  const { proposalId } = useParams<{ proposalId?: string }>();
  const { setSnackbar } = useSnackbarContext();
  const [addSignatoryInProgress, setAddSignatoryInProgress] = useState(false);
  const queryClient = useQueryClient();
  const isEdit = !!data;

  const defaultValues: TSignatoryForm = isEdit
    ? data
    : {
        identifier: "",
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        position: "",
      };

  const { control, handleSubmit, reset, setValue, watch } =
    useForm<TSignatoryForm>({
      defaultValues,
    });

  const address = watch("address");

  const onsubmit = async (formValues: TSignatoryForm) => {
    setAddSignatoryInProgress(true);
    let payload = {
      identifier: formValues.identifier,
      email: formValues.email,
      position: formValues.position,
      name: `${formValues.firstName.trim()} ${formValues.lastName.trim()}`,
      order_identifier: proposalId,
      contact_number: formValues.contactNumber,
      role: "Signatory",
      stakeholder_reference_id: referenceId,
      address_id: formValues.addressId,
      stakeholder_address: {
        address_line_1: formValues.address?.addressLine1,
        address_line_2: formValues.address?.addressLine2,
        zip_code: formValues.address?.zipCode,
        county: formValues.address?.county,
        city: formValues.address?.city,
        address_type: "Mailing",
        identifier: formValues.address?.identifier,
      },
    };
    try {
      if (isEdit) {
        await editSignatory(data?.identifier, payload);
      } else {
        await saveSignatory(payload);
      }
      setSnackbar(
        {
          message: "Signatories saved!",
          open: true,
          variant: null,
        },
        10000
      );
      queryClient.invalidateQueries([QUERY_KEYS.SIGNATORIES]);
      reset();
      onClose();
    } catch (error) {
      setSnackbar(
        {
          message: "Error occurred while saving data",
          open: true,
          variant: "error",
        },
        10000
      );
    } finally {
      setAddSignatoryInProgress(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleAddressChange = (address: TAddressFromControl) => {
    const addressStr = "address" as const;
    setValue(`${addressStr}.addressLine1`, address.address_line_1);
    setValue(`${addressStr}.addressLine2`, address.address_line_2);
    setValue(`${addressStr}.city`, address.city);
    setValue(`${addressStr}.zipCode`, address.zip_code);
    setValue(`${addressStr}.county`, address.county);
  };

  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={handleClose}
      open={open}
      title="Add Signatory Details"
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={handleClose}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text={isEdit ? "Update Signatory" : "Save Signatory"}
            disabled={addSignatoryInProgress}
            onClick={handleSubmit(onsubmit)}
          />
        </>
      }
    >
      <DialogContentContainer
        theme={theme}
        className="dialog-content-container"
      >
        <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
          <Grid theme={theme} item xs={12} md={6}>
            <Controller
              name="firstName"
              control={control}
              defaultValue={defaultValues?.firstName}
              render={({ field }) => (
                <>
                  <Input
                    theme={theme}
                    type="text"
                    label="First Name"
                    fullWidth
                    placeholder="Type here..."
                    value={field.value}
                    {...field}
                  />
                </>
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={6}>
            <Controller
              name="lastName"
              control={control}
              defaultValue={defaultValues?.lastName}
              render={({ field }) => (
                <>
                  <Input
                    theme={theme}
                    type="text"
                    label="Last Name"
                    fullWidth
                    placeholder="Type here..."
                    {...field}
                  />
                </>
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required" }}
              defaultValue={defaultValues.email}
              render={({ field }) => (
                <>
                  <Input
                    theme={theme}
                    type="text"
                    label="Email"
                    fullWidth
                    placeholder="Type here..."
                    {...field}
                  />
                </>
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              name="contactNumber"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    theme={theme}
                    type="text"
                    label="Mobile Phone Number"
                    fullWidth
                    placeholder="Type here..."
                    {...field}
                  />
                </>
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              name="position"
              control={control}
              defaultValue={defaultValues?.position}
              render={({ field }) => (
                <>
                  <Select
                    theme={theme}
                    label="Position"
                    fullWidth
                    items={[
                      {
                        text: "Director",
                        value: "Director",
                      },
                    ]}
                    {...field}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
        <Box theme={theme} className="address-form">
          <AddressLayout
            theme={theme}
            state={{
              data: [],
              selectedItemObject: {
                text: "",
                value: "",
              },
              controlName: "",
            }}
            hideAddressButton={true}
            isContactRequired={false}
            addressVerificationRequired={false}
            countries={[GB_COUNTRY]}
            validationProps={undefined}
            value={
              {
                address_line_1: address?.addressLine1,
                address_line_2: address?.addressLine2,
                city: address?.city,
                zip_code: address?.zipCode,
                county: address?.county,
              } as TAddressFromControl
            }
            onChange={handleAddressChange}
          />
        </Box>
      </DialogContentContainer>
    </Dialog>
  );
};

export default AddSignatoriesDialog;
