import {
  ButtonGroup,
  LayoutWithSideNavComponent,
  PageHeader,
  Stack,
  RangeFilter,
} from "@components";
import { Widget } from "@components/dashboard";
import { useTheme } from "@mui/material";
import { Box, Grid, Typography } from "@ntpkunity/controls";
import RevenueGraph from "../../public/assets/images/revenue-widget.png";
import { PageContent } from "./dashboard-page.style";
import {
  Roles,
  OrderStage,
  OrderStatusCategory,
  SortOrder,
  WorkQueueTabs,
} from "@helpers/enum";
import { useForm, FormProvider } from "react-hook-form";
import {
  DashboardAnalyticsResponse,
  DashboardForm,
  TDashboardSearchParams,
  TSortParams,
} from "@_types";
import {
  DASHBOARD_FORM_DEFAULT_VALUES,
  PROPOSAL_STATUSES_BY_CATEGORY,
  QUEUE_TO_STATUS_MAP,
} from "@helpers/const";
import { ProposalPieChart } from "@components/charts/pie-chart.component";
import { useGetDashboardProposals, useGetDealers } from "@hooks/queries";
import { useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  convertIsoDateStrToddmmyyyy,
  convertIsoDateStrToddmmyyyyhms,
  deleteNDays,
  getEntityTypeDealer,
  getUserDataFromToken,
  getUserSettings,
  reduceDataForAgingProposalWidget,
  reduceDataForProposalWidget,
  reduceDataForTotalProposalWidget,
} from "@helpers/utils";
import { DashboardTable } from "@components/dashboard/dashboard-table/dashboard-table.component";
import { ProposalBarChart } from "@components/charts/bar-chart/bar-chart.component";

const sortInitialState: TSortParams<keyof TDashboardSearchParams> = {
  sort_by: "updated_at",
  sort_order: SortOrder.DESC,
};

const searchInitialState: TDashboardSearchParams = {
  name: "",
  finance_type: "",
  finance_amount: undefined,
  updated_at: "",
  order_stage: OrderStage.APPLICATION,
  customer_name: "",
  date_submitted: "",
  introducer_name: "",
  lender_name: "",
  dealer_name: "",
  dealer_id: null,
  broker_id: null,
  updated_at_from: convertIsoDateStrToddmmyyyyhms(
    deleteNDays(30).toISOString()
  ),
  updated_at_to: convertIsoDateStrToddmmyyyyhms(new Date().toISOString()),
  statuses: QUEUE_TO_STATUS_MAP[WorkQueueTabs.DASHBOARD_PROPOSALS],
};

export const DashboardPage = () => {
  const theme = useTheme();
  const { data: dealerAssociation } = useGetDealers(getEntityTypeDealer());
  const userSettings = getUserSettings();
  const brokerId =
    userSettings?.role?.name == Roles.BROKER_USER
      ? getUserDataFromToken()?.user_id
      : null;

  const [searchParams, setSearchParams] =
    useState<TDashboardSearchParams>(searchInitialState);
  const [tableSearchParams, setTableSearchParams] =
    useState<TDashboardSearchParams>();
  const [totalSearchParams, setTotalSearchParams] =
    useState<TDashboardSearchParams>(searchInitialState);
  const [agingSearchParams, setAgingSearchParams] =
    useState<TDashboardSearchParams>(searchInitialState);
  const [sortParams] =
    useState<TSortParams<keyof TDashboardSearchParams>>(sortInitialState);
  const [debouncedSearchParams] = useDebounce(
    useMemo(() => {
      return {
        ...searchParams,
        dealer_id: dealerAssociation?.[0]?.id || null,
        broker_id: brokerId,
        updated_at: convertIsoDateStrToddmmyyyy(searchParams.updated_at),
        date_submitted: convertIsoDateStrToddmmyyyy(
          searchParams.date_submitted
        ),
      };
    }, [searchParams, dealerAssociation]),
    500
  );

  const [debouncedTotalSearchParams] = useDebounce(
    useMemo(() => {
      return {
        ...totalSearchParams,
        dealer_id: dealerAssociation?.[0]?.id || null,
        broker_id: brokerId,
        updated_at: convertIsoDateStrToddmmyyyy(totalSearchParams.updated_at),
        date_submitted: convertIsoDateStrToddmmyyyy(
          totalSearchParams.date_submitted
        ),
      };
    }, [totalSearchParams, dealerAssociation]),
    500
  );

  const [debouncedAgingSearchParams] = useDebounce(
    useMemo(() => {
      return {
        ...agingSearchParams,
        dealer_id: dealerAssociation?.[0]?.id || null,
        broker_id: brokerId,
        statuses: PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE],
        updated_at: convertIsoDateStrToddmmyyyy(agingSearchParams.updated_at),
        date_submitted: convertIsoDateStrToddmmyyyy(
          agingSearchParams.date_submitted
        ),
      };
    }, [agingSearchParams, dealerAssociation]),
    500
  );

  const totalPieChartRef = useRef<any>();
  const activePieChartRef = useRef<any>();
  const { data: proposals } = useGetDashboardProposals<
    TDashboardSearchParams,
    DashboardAnalyticsResponse
  >(debouncedSearchParams, sortParams);

  const { data: totalProposalsProposals } = useGetDashboardProposals<
    TDashboardSearchParams,
    DashboardAnalyticsResponse
  >(debouncedTotalSearchParams, sortParams);

  const { data: agingProposalsProposals } = useGetDashboardProposals<
    TDashboardSearchParams,
    DashboardAnalyticsResponse
  >(debouncedAgingSearchParams, sortParams);

  const formMethods = useForm<DashboardForm>({
    defaultValues: DASHBOARD_FORM_DEFAULT_VALUES,
  });

  const activeProposalWidgetData = useMemo(
    () => reduceDataForProposalWidget(theme, proposals),
    [proposals]
  );

  const totalProposalWidgetData = useMemo(
    () => reduceDataForTotalProposalWidget(theme, totalProposalsProposals),
    [totalProposalsProposals]
  );

  const agingProposalWidgetData = useMemo(
    () => reduceDataForAgingProposalWidget(agingProposalsProposals),
    [agingProposalsProposals]
  );
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <FormProvider {...formMethods}>
        <PageHeader title="Dashboard" />
        <PageContent theme={theme} className="page-content" pt={3}>
          <Grid theme={theme} container item spacing={2} alignItems={"stretch"}>
            <Grid theme={theme} item xs={12} md={6} lg={4}>
              <Widget
                control="active-proposal"
                title="Active Proposals"
                expand={true}
                actionArea={
                  <RangeFilter
                    name={"rangeFilter"}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                }
              >
                <Box
                  theme={theme}
                  className="graph-ph"
                  textAlign={"center"}
                  height={384}
                >
                  <ProposalPieChart
                    data={activeProposalWidgetData}
                    radius={120}
                    donutWidth={15}
                    centerText={"Total Active Proposals"}
                    ref={activePieChartRef}
                    onClick={(props, _index, activeIndex) => {
                      if (activeIndex) {
                        totalPieChartRef?.current?.updateIndex(null);
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses: [props.status],
                        });
                      } else {
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses:
                            QUEUE_TO_STATUS_MAP[
                              WorkQueueTabs.DASHBOARD_PROPOSALS
                            ],
                        });
                      }
                    }}
                  />
                </Box>
              </Widget>
            </Grid>
            <Grid theme={theme} item xs={12} md={6} lg={4}>
              <Widget
                control="total-proposal"
                title={"Proposals"}
                actionArea={
                  <RangeFilter
                    name={"totalRangeFilter"}
                    searchParams={totalSearchParams}
                    setSearchParams={setTotalSearchParams}
                  />
                }
              >
                <Box
                  theme={theme}
                  className="graph-ph"
                  textAlign={"center"}
                  height={360}
                >
                  <ProposalPieChart
                    data={totalProposalWidgetData}
                    radius={120}
                    donutWidth={15}
                    centerText={"Total Proposals"}
                    ref={totalPieChartRef}
                    onClick={(props, _index, activeIndex) => {
                      if (activeIndex) {
                        activePieChartRef?.current?.updateIndex(null);
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses: PROPOSAL_STATUSES_BY_CATEGORY[props.status],
                        });
                      } else {
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses:
                            QUEUE_TO_STATUS_MAP[
                              WorkQueueTabs.DASHBOARD_PROPOSALS
                            ],
                        });
                      }
                    }}
                  />
                </Box>
              </Widget>
            </Grid>
            <Grid theme={theme} item xs={12} md={6} lg={4}>
              <Widget
                control="active-proposal"
                title="Proposal Aging"
                actionArea={
                  <RangeFilter
                    name={"agingRangeFilter"}
                    searchParams={agingSearchParams}
                    setSearchParams={setAgingSearchParams}
                  />
                }
              >
                <Box theme={theme} className="graph-text">
                  <Typography
                    theme={theme}
                    component={"h2"}
                    variant="h2"
                    lineHeight={"32px"}
                  >
                    {agingProposalsProposals?.length}
                  </Typography>
                  <Typography
                    theme={theme}
                    component={"p"}
                    variant="body1"
                    className="text-muted"
                    mt={1}
                  >
                    Total number of Proposals
                  </Typography>
                </Box>
                <Box
                  theme={theme}
                  className="graph-ph"
                  textAlign={"center"}
                  height={270}
                  mt={3}
                >
                  <ProposalBarChart
                    chartData={agingProposalWidgetData}
                    layout={"horizontal"}
                    xAxisDataKey={"category"}
                    xAxisType={"category"}
                    yAxisDataKey={"count"}
                  />
                </Box>
              </Widget>
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Stack
                paddingMd={3}
                paddingXs={3}
                className="stack-mobile"
                title="Proposals Table"
              >
                <Box theme={theme} mt={{ xs: 3, md: 0 }}>
                  <DashboardTable
                    searchParams={tableSearchParams}
                    setSearchParams={setTableSearchParams}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </PageContent>
      </FormProvider>
    </LayoutWithSideNavComponent>
  );
};
