import { useGetFinanceTypes, useGetProductTypes } from "@hooks/queries";

const useProposalDetailsSetups = () => {
  const productTypesQuery = useGetProductTypes();
  const financeTypesQuery = useGetFinanceTypes();

  const financeTypes = financeTypesQuery.isSuccess
    ? financeTypesQuery.data
    : [];
  const productTypes = productTypesQuery.isSuccess
    ? productTypesQuery.data
    : [];

  return {
    financeTypes,
    productTypes,
  };
};

export default useProposalDetailsSetups;
