import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getAllLenders } from "@services/index.service";
import useGetCompanyByTenantId from "./useGetCompanyProfile";
import { Lender } from "@_types/company";

const useGetAllLenders = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, Lender[]>({
    queryKey: [QUERY_KEYS.GET_ALL_LENDERS, companyId],
    queryFn: () => getAllLenders(companyId),
    enabled: !!companyId,
  });
};

export default useGetAllLenders;
