import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getCompanyByTenantId } from "@services/index.service";
import { getUserSettings } from "@helpers/utils";
import { CompanyInfo } from "@_types/company";

const useGetCompanyByTenantId = () => {
  const tenantId = getUserSettings().tenant_id;

  return useQuery<number, unknown, CompanyInfo>({
    queryKey: [QUERY_KEYS.GET_COMPANY_PROFILE, tenantId],
    queryFn: () => getCompanyByTenantId(tenantId),
    enabled: !!tenantId,
  });
};

export default useGetCompanyByTenantId;
