import { useTheme } from "@mui/material";
import { Box, Button, Checkbox, Grid } from "@ntpkunity/controls";
import { CustomerDetails } from "@components/document/summary/customer-details/customer-details.component";
import { PaymentDetails } from "@components/document/summary/payment-details/payment-details.component";
import { ProposalSummaryWrap } from "@components/document/summary/proposal-summary.style";
import { TotalAssetCost } from "@components/document/summary/total-asset-cost.component";
import { DirectorDetails } from "@components/document";
import AssetDetailsTable from "@components/document/summary/asset-details-table/asset-details-table.component";
import { Stack } from "@components";
import { CommentList } from "./comment-list/comment-list.component";
import { useState } from "react";
import AddCommentDialog from "./add-comment-dialog/add-comment-dialog.component";
import { TCommentsDetails } from "@_types";
import { CommentCategory } from "@helpers/enum";

export const CustomerProposal = () => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [comments, setComments] = useState<TCommentsDetails[]>([]);
  const [commentToEdit, setCommentToEdit] = useState<number | null>(null);
  const handleCommentAdd = (newComment: TCommentsDetails) => {
    if (commentToEdit) {
      setComments((prevComments) =>
        prevComments.map((c) =>
          c.identifier === newComment.identifier ? newComment : c
        )
      );
      setCommentToEdit(null);
    } else {
      setComments((prevComments) => [...prevComments, newComment]);
    }
    setDialogOpen(false);
  };
  const handleCommentEdit = (index: number) => {
    setCommentToEdit(index);
    setDialogOpen(true);
  };
  const handleCommentDelete = (index: number) => {
    return;
  };
  return (
    <>
      <ProposalSummaryWrap theme={theme} className="proposal-summary-wrap">
        <Grid theme={theme} container spacing={2}>
          <Grid theme={theme} item xl={9} lg={8} md={7} sm={12} xs={12}>
            <Box theme={theme} display={{ xs: "block", md: "none" }}>
              <TotalAssetCost />
            </Box>
            <Box theme={theme}>
              <Stack paddingXs={3} paddingMd={3} className="checkbox">
                <Checkbox
                  theme={theme}
                  label={
                    <>
                      I agree to{" "}
                      <a href="#" className="text-primary">
                        terms and conditions
                      </a>{" "}
                      and confirm provided details are correct and consent to
                      the credit and reference checks.
                    </>
                  }
                  checkBoxChecked
                />
              </Stack>
              <Stack
                paddingXs={3}
                paddingMd={3}
                className="checkbox"
                marginTopMd={2}
                marginTopXs={2}
              >
                <Checkbox
                  theme={theme}
                  label={
                    <>
                      I agree to{" "}
                      <a href="#" className="text-primary">
                        GDPR
                      </a>{" "}
                      and{" "}
                      <a href="#" className="text-primary">
                        Privacy policy
                      </a>{" "}
                      of Link.
                    </>
                  }
                  checkBoxChecked
                />
              </Stack>
            </Box>
            <Box theme={theme} mt={2}>
              <CustomerDetails hasEdit />
            </Box>
            <Box theme={theme} mt={2}>
              <DirectorDetails />
            </Box>
            <Box theme={theme} mt={2}>
              <Stack
                paddingXs={3}
                paddingMd={3}
                title="Comments for Underwriter"
                actionArea={
                  <>
                    <Button
                      theme={theme}
                      secondary
                      text={"Add Comment"}
                      onClick={() => setDialogOpen(true)}
                    />
                  </>
                }
              >
                <CommentList
                  comments={comments}
                  onEdit={handleCommentEdit}
                  onDelete={handleCommentDelete}
                />
              </Stack>
            </Box>
            <Box theme={theme} mt={2}>
              <Stack paddingXs={3} paddingMd={3} title="Asset Details">
                <Box theme={theme} mt={2}>
                  <AssetDetailsTable />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            theme={theme}
            item
            xl={3}
            lg={4}
            md={5}
            sm={12}
            xs={12}
            display={{ xs: "none", md: "block" }}
          >
            <PaymentDetails />
          </Grid>
        </Grid>
      </ProposalSummaryWrap>
      <AddCommentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCommentAdd={handleCommentAdd}
        commentToEdit={{
          identifier: "",
          orderReferenceId: "string",
          customerId: 1,
          comment: "string;",
          category: CommentCategory.GENERAL,
          createdAt: "string;",
          createdBy: "string;",
          updatedAt: "string;",
          updatedBy: "string;",
        }}
      />
    </>
  );
};
