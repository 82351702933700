import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getComplianceDocuments } from "@services/index.service";
import { ComlianceDocumentSetupRes } from "@_types/setups";
import { useGetCompanyByTenantId } from "@hooks/queries";

const useGetComlianceDocuments = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, ComlianceDocumentSetupRes>({
    queryKey: [QUERY_KEYS.GET_COMPLIANCE_DOCUMENTS, companyId],
    queryFn: () => getComplianceDocuments(companyId),
    enabled: !!companyId,
  });
};

export default useGetComlianceDocuments;
