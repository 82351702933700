import {
  LayoutWithSideNavComponent,
  Snackbar,
  Workflows,
  ConfigurationWrapper as ConfigurationContextProvider,
} from "@components";
import { useTheme } from "@mui/material";
import { ProposalPageWrap } from "./proposal-page.style";
import { Proposal } from "@components/proposal";
import { useGetDealers, useGetProposal } from "@hooks/queries";
import { useParams, useLocation } from "react-router-dom";
import { OrderStatus, ProposalTabs } from "@helpers/enum";
import useGetAllActiveDealers from "@hooks/queries/useGetAllDealers";
import useGetAllLenders from "@hooks/queries/useGetAllLenders";
import { getEntityTypeDealer, isDealerRole } from "@helpers/utils";
import { CircleLoader } from "@ntpkunity/controls";
import { useEffect } from "react";
import QUERY_KEYS from "@helpers/query-keys";
import { useQueryClient } from "react-query";
export type RouterState = {
  proposalId?: string; // Clone Proposal
  quotationId?: string; // Clone Quotation
  tab?: ProposalTabs; // Opening specific tab
};

export const ProposalPage = () => {
  const theme = useTheme();
  const params = useParams<{ proposalId?: string }>();
  const location = useLocation();
  const {
    data: dealerAssociation,
    isSuccess: isSuccessDealer,
    isLoading: isDealerLoading,
  } = useGetDealers(getEntityTypeDealer());

  useGetAllActiveDealers();
  useGetAllLenders();
  const routerState: RouterState = location.state;
  const cloneProposalId = routerState?.proposalId;
  const isEditMode = !!params.proposalId || !!cloneProposalId;
  const queryClient = useQueryClient();

  const { data, isSuccess } = useGetProposal(
    params.proposalId || cloneProposalId
  );

  if (cloneProposalId && data) {
    data.proposal.documents = []
    data.proposal.status = OrderStatus.DRAFT 
  };

  useEffect(() => {
    if (params?.proposalId) {
      const invalidateQueries = async () => {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_SINGLE_PROPOSAL, params.proposalId],
        });
      };
      return () => {
        invalidateQueries();
      };
    }
  }, [params?.proposalId]);
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ProposalPageWrap theme={theme} className="proposal-page-wrap">
        {(!isEditMode || isSuccess) && (
          <Workflows runningId={data?.runningId}>
            <ConfigurationContextProvider
              retainFinanceType={data?.proposal.proposalDetails.financeType}
            >
              {isDealerRole() && isDealerLoading ? (
                <CircleLoader theme={theme} />
              ) : !isDealerRole() || (dealerAssociation && isSuccessDealer) ? (
                <Proposal proposalData={data} />
              ) : (
                <></>
              )}
            </ConfigurationContextProvider>
          </Workflows>
        )}
        <Snackbar />
      </ProposalPageWrap>
    </LayoutWithSideNavComponent>
  );
};
