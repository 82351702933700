import React from "react";
import { useTheme } from "@mui/material";
import { CommentListWrap } from "./comment-list.style";
import {
  Avatar,
  Box,
  Button,
  Icon,
  Menu,
  Typography,
} from "@ntpkunity/controls";
import { CommentListProps } from "./comment-list.props";
import { getUserNameInitials, utcToLocalDateTime } from "@helpers/utils";
import { TableRowOptions, CommentCategory } from "@helpers/enum";

export const CommentList = ({
  comments,
  onEdit,
  onDelete,
}: CommentListProps) => {
  const theme = useTheme();

  const filteredComments = comments?.filter(
    (comment) => comment.category === CommentCategory.UNDERWRITING
  );

  const handleOptionClick = (_, key, value) => {
    if (key === TableRowOptions.VIEW_EDIT) {
      onEdit(value);
      return;
    }
    if (key === TableRowOptions.DELETE) {
      onDelete(value);
    }
  };

  return (
    <CommentListWrap theme={theme} className="comment-list-wrap">
      {filteredComments?.map((comment, index) => {
        const commentDateTime = comment.updatedAt || comment.createdAt;
        const { dateStr, timeStr } = utcToLocalDateTime(commentDateTime);
        const nameParts =
          (comment.updatedBy || comment.createdBy)?.trim().split(/\s+/) ?? [];
        const userNameInitials = nameParts
          ?.map((part) => part.charAt(0).toUpperCase())
          .join("");

        return (
          <Box theme={theme} className="comment-item" key={index}>
            <Box theme={theme} className="header">
              <Box theme={theme} className="user-name">
                <Avatar className="avatar" theme={theme}>
                  {userNameInitials}
                </Avatar>
                <Box theme={theme} className="avatar-text">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="small"
                    display={"block"}
                  >
                    {comment.updatedBy || comment.createdBy}
                  </Typography>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="small"
                    className="text-muted subtitle"
                    mt={0.5}
                  >
                    {dateStr}. <span className="time">{timeStr}</span>
                  </Typography>
                </Box>
              </Box>
              <Box theme={theme} className="action-area">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: "View/Edit",
                      optionkey: TableRowOptions.VIEW_EDIT,
                      optionValue: index,
                    },
                    {
                      optionText: "Delete",
                      optionkey: TableRowOptions.DELETE,
                      optionValue: index,
                    },
                  ]}
                  handleOptionClick={handleOptionClick}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    />
                  )}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </Box>
            </Box>
            <Typography
              theme={theme}
              variant="body2"
              component="p"
              className="text-muted text-pre-line"
              mt={2}
            >
              {comment?.comment}
            </Typography>
          </Box>
        );
      })}
    </CommentListWrap>
  );
};
