import { Button, Icon, Menu } from "@ntpkunity/controls";
import {
  NewQuotationFiltersHeader,
  PageHeader,
  DownloadQuotationDialog,
} from "@components";
import { useHasPermissions } from "@ntpkunity/controls-ums";
import { useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbarContext } from "@contexts/snackbar";
import {
  BrokerQuotationForm,
  BrokerSpecifcFormFields,
  DownloadQuoteAdditionalParams,
  QuotationDownloadDialogData,
} from "@_types";
import { APP_ROUTES } from "@helpers";
import {
  DownloadDocument,
  Events,
  ProposalMode,
  TableRowOptions,
  UserPermissions,
} from "@helpers/enum";
import {
  useCalculationsContext,
  useGetMutateQuotationPayload,
  useQuotationFormContext,
  EvaluationExternalParams,
} from "@ntpkunity/controls-common";
import {
  errorInQuotationDetails,
  getBrokerQuotationPayload,
  getDownloadDocumentPayload,
} from "@helpers/utils";
import { useForm, FormProvider } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  useSetupsSelector,
  useDialogState,
  useGetCompanyByTenantId,
  useGetDealers,
  useConvertQuotationToProposal,
  useDownloadDocs,
  useSaveQuotation,
  useSendEmailNotification,
  useUpdateQuotation,
  useGetCurrency,
} from "@hooks";
import { SNACKBAR_MESSAGES } from "@helpers/const";

type NewQuotationPageHeaderProps = {
  brokerDefaultValues?: BrokerSpecifcFormFields;
  setEvaluationParams: React.Dispatch<
    React.SetStateAction<EvaluationExternalParams>
  >;
};

export const NewQuotationPageHeader = ({
  brokerDefaultValues,
  setEvaluationParams,
}: NewQuotationPageHeaderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams<{ quotationId?: string }>();
  const quotationId = params.quotationId;
  const isEditMode = !!quotationId;
  const [quotationIdentifier, setQuotationIdentifier] = useState(quotationId);
  const {
    dialogOpen: downloadDialogOpened,
    closeDialog: closeDownloadDialog,
    openDialog: openDownloadDialog,
    dialogData: downloadDialogData,
  } = useDialogState<QuotationDownloadDialogData>();

  const formMethods = useForm<BrokerSpecifcFormFields>({
    defaultValues: !!brokerDefaultValues ? brokerDefaultValues : undefined,
  });
  const { data: calculations, calculated } = useCalculationsContext();
  const { mutateAsync: saveQuotation } = useSaveQuotation();
  const { mutateAsync: updateQuotation } = useUpdateQuotation();
  const { mutateAsync: convertToProposal } = useConvertQuotationToProposal();
  const { mutateAsync: downloadDocs, isLoading: downloadingQuotation } =
    useDownloadDocs();
  const { mutateAsync: sendQuotationEmail, isLoading: sendingEmail } =
    useSendEmailNotification();
  const { data: companyInfo } = useGetCompanyByTenantId();

  const { setSnackbar } = useSnackbarContext();
  const {
    getValues: getQuotationValues,
    watch,
    formState: { isDirty: formDirty },
  } = useQuotationFormContext<BrokerQuotationForm>();
  const currencyId = watch("currency");
  const { data: currency } = useGetCurrency(currencyId);

  const getSaveQuotationPayload = useGetMutateQuotationPayload({
    forUpdate: !!quotationId,
  });

  const setups = useSetupsSelector((state) => ({
    financeTypes: state.financeTypes,
    clientTypes: state.clientTypes,
  }));
  const clientTypes = setups.clientTypes.data;
  const financeTypes = setups.financeTypes.data;

  const disableBtn = !calculated || formDirty;
  const { data: dealerAssociation } = useGetDealers();
  const clientTypeCode = formMethods.watch("clientType");
  const assetTypeStr = formMethods.watch("asset.type");
  const clientTypeName = clientTypes?.find(
    (ct) => ct.code === clientTypeCode
  )?.description;

  const handleSaveQuotation = async () => {
    const error = errorInQuotationDetails(formMethods.getValues());
    if (error.isError) {
      setSnackbar(
        { message: error.message, open: true, variant: "error" },
        5000
      );
      return;
    }
    const brokerSpecificValues = formMethods.getValues();
    const quotationPayload = getSaveQuotationPayload();

    const payload = getBrokerQuotationPayload(
      quotationPayload,
      brokerSpecificValues,
      dealerAssociation,
      isEditMode
    );
    try {
      setSnackbar({ message: "Saving Quotation", open: true });
      if (isEditMode) {
        await updateQuotation({ ...payload, identifier: quotationId });
        setSnackbar({ message: "Quotation Saved Successfully", open: true });
        return quotationIdentifier;
      } else {
        const response = await saveQuotation(payload);
        setQuotationIdentifier(response.data.identifier);
        setSnackbar({ message: "Quotation Saved Successfully", open: true });
        return response.data.identifier as string;
      }
    } catch {
      setSnackbar({
        open: true,
        message: "Error Occurred while saving Quotation",
        variant: "error",
      });
    }
  };

  const handleConvertToProposal = async () => {
    try {
      let identifier = quotationIdentifier;
      if (!quotationIdentifier) {
        const response = await handleSaveQuotation();
        if (!response) {
          return;
        }
        identifier = response;
      }
      setSnackbar({ open: true, message: "Converting To Proposal..." });
      await convertToProposal(identifier);
      navigate(`${APP_ROUTES.PROPOSAL}/${ProposalMode.EDIT}/${identifier}`);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error occurred while converting to proposal",
        variant: "error",
      });
    }
  };

  const getDocumentGenerationData = (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    const brokerSpecificValues = formMethods.getValues();
    const quotationPayload = getQuotationValues();

    return getDownloadDocumentPayload(
      {
        documentType: DownloadDocument.QUOTATION,
        additionalParams: { ...brokerSpecificValues, ...additionalParams },
        financialCalculations: calculations,
        financialValues: quotationPayload,
        setups: {
          clientTypes: clientTypes,
          financeTypes: financeTypes,
        },
        companyId: companyInfo.id,
      },
      currency
    );
  };

  const validateEmails = (customerEmail: string) => {
    if (!companyInfo.email) {
      throw new Error(SNACKBAR_MESSAGES.COMPANY_EMAIL_INVALID);
    }
    if (!customerEmail) {
      throw new Error(SNACKBAR_MESSAGES.INVALID_CUSTOMER_EMAIL);
    }
  };

  const handleEmailQuotation = async (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    const downloadQuotationPayload =
      getDocumentGenerationData(additionalParams);
    const { email_address: customerEmail } = downloadQuotationPayload;
    validateEmails(customerEmail);
    setSnackbar({
      open: true,
      message: SNACKBAR_MESSAGES.SENDING_EMAIL,
    });
    await sendQuotationEmail({
      eventName: Events.EMAIL_QUOTATION,
      payload: {
        source_email: companyInfo.email,
        bcc_emails: [],
        cc_emails: [],
        data: {
          ...downloadQuotationPayload,
          reference_number: "",
          customer_reference_id: "",
        },
        email_subject: `Finance Quotation - ${downloadQuotationPayload.name}`,
        email: customerEmail,
        email_attachment_keys: [],
      },
    });
    setSnackbar({ open: true, message: SNACKBAR_MESSAGES.EMAIL_SENT }, 2000);
  };

  const handleDownloadQuotation = async (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    const downloadQuotationPayload =
      getDocumentGenerationData(additionalParams);
    setSnackbar({
      open: true,
      message: SNACKBAR_MESSAGES.DOWNLOADING_QUOTATION,
    });
    await downloadDocs({
      payload: downloadQuotationPayload,
      eventName: Events.DOWNLOAD_QUOTATION,
    });
    setSnackbar(
      { open: true, message: SNACKBAR_MESSAGES.QUOTATION_DOWNLOADED },
      2000
    );
  };

  const handleGenerateDocuments = async (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    try {
      if (downloadDialogData.rowOptions === TableRowOptions.DOWNLOAD) {
        await handleDownloadQuotation(additionalParams);
      } else {
        await handleEmailQuotation(additionalParams);
      }
      closeDownloadDialog();
    } catch (err) {
      const errorMessage =
        err.message || SNACKBAR_MESSAGES.UNEXPECTED_ERROR_OCCURRED;
      setSnackbar({
        open: true,
        message: errorMessage,
        variant: "error",
      });
    }
  };

  const handleOptionClick = (_, key: TableRowOptions) => {
    switch (key) {
      case TableRowOptions.CONVERT_TO_PROPOSAL:
        handleConvertToProposal();
        break;
      case TableRowOptions.DOWNLOAD:
        openDownloadDialog({ clientType: clientTypeName, rowOptions: key });
        break;
      case TableRowOptions.EMAIL_QUOTATION:
        openDownloadDialog({ clientType: clientTypeName, rowOptions: key });
    }
  };

  // Sync external param's client type
  useEffect(() => {
    if (!assetTypeStr) return;
    const assetSplit = assetTypeStr.split(" - ");
    const assetCategory = assetSplit[0];
    const assetType = assetSplit[1];
    setEvaluationParams?.((prevState) => ({
      ...prevState,
      asset_type: assetType,
      asset_category: assetCategory,
    }));
  }, [assetTypeStr]);

  // Sync external param's asset category & asset type
  useEffect(() => {
    if (!clientTypeName) return;
    setEvaluationParams?.((prevState) => ({
      ...prevState,
      customer_type: clientTypeName,
    }));
  }, [clientTypeName]);

  return (
    <FormProvider {...formMethods}>
      <PageHeader
        title={false}
        hasInput
        inputName="name"
        inputPlaceholder="Enter Quotation Name..."
        shouldUseForm={true}
        actionArea={
          <>
            <NewQuotationFiltersHeader />
            <Menu
              theme={theme}
              options={[
                {
                  optionText: "Convert to proposal",
                  optionkey: TableRowOptions.CONVERT_TO_PROPOSAL,
                  disabled: !isEditMode && disableBtn,
                },
                {
                  optionText: "Download Quotation",
                  optionkey: TableRowOptions.DOWNLOAD,
                  disabled: !isEditMode && disableBtn,
                },
                {
                  optionText: "Email Quotation",
                  optionkey: TableRowOptions.EMAIL_QUOTATION,
                  disabled: !isEditMode && disableBtn,
                },
              ]}
              handleOptionClick={handleOptionClick}
              render={(cb) => (
                <Button
                  secondary
                  theme={theme}
                  iconText={<Icon name="MoreIcon" />}
                  onClick={cb}
                ></Button>
              )}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
            {useHasPermissions(UserPermissions.UPDATE_RATES_BUTTON) && (
              <Button
                theme={theme}
                type="button"
                secondary
                text="Update Rates"
              />
            )}
            <Button
              theme={theme}
              type="button"
              primary
              text="Save Quotation"
              disabled={disableBtn}
              onClick={handleSaveQuotation}
            />
          </>
        }
      />
      {downloadDialogOpened && (
        <DownloadQuotationDialog
          dialogOpen={downloadDialogOpened}
          downloadQuotation={handleGenerateDocuments}
          closeDialog={closeDownloadDialog}
          dialogData={downloadDialogData}
          btnDisabled={downloadingQuotation || sendingEmail}
        />
      )}
    </FormProvider>
  );
};
