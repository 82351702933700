import { AuthLayout, SignInForm } from "@components";
import { useTheme } from "@mui/material";
import LoginSideBarImage from "../../../public/assets/images/login-sidebar-img.jpg";
import AppLogo from "../../../public/assets/images/app-default-logo.svg";

export const SignInPage = () => {
  const theme = useTheme();

  return (
    <AuthLayout logoImage={AppLogo} sideBarImage={LoginSideBarImage}>
      <SignInForm title={"Link"} />
    </AuthLayout>
  );
};
