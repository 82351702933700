import { useQuery, UseQueryResult } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getProposalForDashboard } from "@services/dms.service";
import { TSortParams } from "@_types";
import { Roles } from "@helpers/enum";
import { getUserDataFromToken, getUserSettings } from "@helpers/utils";

const useGetOrders = <
  SearchParams extends { [key: string]: any },
  ReturnType extends { [key: string]: any }
>(
  searchParams: SearchParams,
  sortParams: TSortParams<keyof SearchParams>
): UseQueryResult<ReturnType[]> => {
  const userSettings = getUserSettings();
  const userRoleName = userSettings?.role?.name;
  const isDealerUsers = !!(
    searchParams?.dealer_id &&
    [Roles.BROKER_DEALER_ADMIN, Roles.BROKER_DEALER_USER].includes(userRoleName)
  );
  const _brokerId =
    userRoleName == Roles.BROKER_USER
      ? getUserDataFromToken()?.user_id
      : undefined;
  const isBrokerUser = !!(searchParams.broker_id && _brokerId);
  const isBrokerAdmin = userRoleName == Roles.BROKER_ADMIN;

  const isEnabled = !!(isDealerUsers || isBrokerUser || isBrokerAdmin);
  return useQuery<ReturnType[]>({
    queryKey: [QUERY_KEYS.DASHBOARD_PROPOSALS, searchParams, sortParams],
    queryFn: () => getProposalForDashboard(searchParams, sortParams),
    enabled: isEnabled,
  });
};

export default useGetOrders;
