import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getDmsDealers } from "@services/dms.service";
import { DealerProfile } from "@_types/company";

const useGetDealers = (entityType: string) => {
  return useQuery<string, unknown, DealerProfile[]>({
    queryKey: [QUERY_KEYS.GET_DMS_DEALER, entityType],
    queryFn: () => getDmsDealers(entityType),
    enabled: !!entityType,
  });
};

export default useGetDealers;
