import {
  getFinanceTypePayload,
  getFinanceTypesForDealer,
  isDealerRole,
} from "@helpers/utils";
import { useGetFinanceTypes } from "@hooks/queries";
import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  CircleLoader,
  Icon,
  PageAlert,
} from "@ntpkunity/controls";
import { ConfigurationContextProvider } from "@ntpkunity/controls-common";
import { useNavigate } from "react-router-dom";

type ConfigurationWrapperProps = {
  retainFinanceType?: string;
  children: React.ReactNode;
};

export const ConfigurationWrapper = ({
  retainFinanceType,
  children,
}: ConfigurationWrapperProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    isLoading: financeTypesLoading,
    isError: isErrorInFinanceTypes,
    data: financeTypes,
  } = useGetFinanceTypes();
  const _isDealerRole = isDealerRole();

  if (financeTypesLoading) return <CircleLoader theme={theme} />;

  if (isErrorInFinanceTypes) {
    return (
      <PageAlert
        theme={theme}
        icon="DialogAlertIcon"
        title="Oops! Unexpected Error occurred."
        variant="full-page"
        message="We encountered an unexpected error. Try refreshing the page."
        actionArea={
          <>
            <Button
              theme={theme}
              fullWidth
              secondary
              startIcon={<Icon name="IconLeftArrow" />}
              text="Go Back"
              onClick={() => navigate(-1)}
            />
            <Button
              theme={theme}
              fullWidth
              danger
              startIcon={<Icon name="ResetIcon" />}
              text="Refresh Page"
              onClick={() => window.location.reload()}
            />
          </>
        }
      />
    );
  }

  if (!financeTypes?.length) {
    return (
      <Box theme={theme} className="primary-alert">
        <PageAlert
          theme={theme}
          icon="DialogInfoIcon"
          title="Finance types not configured"
          variant="full-page"
          message="Please configure finance types to access this page"
          actionArea={
            <Button
              theme={theme}
              fullWidth
              primary
              startIcon={<Icon name="IconLeftArrow" />}
              text="Go Back"
              onClick={() => navigate(-1)}
            />
          }
        />
      </Box>
    );
  }

  if (financeTypes?.length) {
    const configFinanceTypes = _isDealerRole
      ? getFinanceTypesForDealer(financeTypes)
      : financeTypes;
    return (
      <ConfigurationContextProvider
        financeTypes={getFinanceTypePayload(
          retainFinanceType,
          configFinanceTypes
        )}
      >
        {children}
      </ConfigurationContextProvider>
    );
  }
};
