import { Box, Button, Grid, Icon, Menu, Typography } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AddSignatoriesListWrap } from "./add-signatories.style";
import { Template, SignatoryResponse, TSignatoryForm } from "@_types";
import { useParams } from "react-router-dom";
import AddSignatoriesDialog from "./add-signatories-dialog.component";
import { useDialogState } from "@hooks";
import { OrderStatus, SignatoryRowOptions } from "@helpers/enum";
import { deleteSignatory } from "@services";
import { useSnackbarContext } from "@contexts/snackbar";
import { QUERY_KEYS } from "@helpers/query";
import { useQueryClient } from "react-query";
import { SignatoriesListProps } from "./signatories-list-props";
import clsx from "clsx";

const SignatoriesList = ({
  complianceDocuments,
  signatories,
  isGeneratedDocument,
  proposalStatus,
}: SignatoriesListProps) => {
  const {
    dialogOpen: signatoryDialogOpened,
    dialogData: signatoryDialogData,
    openDialog: openSignatoryDialog,
    closeDialog: closeSignatoryDialog,
  } = useDialogState<TSignatoryForm>();
  const { setSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();

  const hideActionButtons =
    isGeneratedDocument || proposalStatus === OrderStatus.AWAITING_COMPLIANCE;
  const handleDelete = async (identifier: string) => {
    try {
      await deleteSignatory(identifier);
      setSnackbar(
        {
          message: "Signatory Deleted!",
          open: true,
          variant: null,
        },
        10000
      );
      await queryClient.invalidateQueries([QUERY_KEYS.SIGNATORIES]);
    } catch (e) {
      setSnackbar(
        {
          message: "Deletion Failed!",
          open: true,
          variant: null,
        },
        10000
      );
    }
  };
  const templateNames = complianceDocuments?.flatMap((item) =>
    item.templates.map((template: Template) => template.template_name)
  );
  const uniqueTemplateNames = Array.from(new Set(templateNames));

  const handleOptionClick = (_, key: SignatoryRowOptions, signatory: any) => {
    if (key === SignatoryRowOptions.VIEW_EDIT) {
      const address = signatory.stakeholder_address;
      const nameSplit = signatory.name.split(" ");
      openSignatoryDialog({
        identifier: signatory.identifier,
        firstName: nameSplit[0],
        lastName: nameSplit[1],
        email: signatory.email,
        position: signatory.position,
        contactNumber: signatory.contact_number,
        addressId: signatory.address_id,
        documents: signatory.stakeholder_reference_id,
        address: {
          addressLine1: address?.address_line_1,
          addressLine2: address?.address_line_2,
          city: address?.city,
          county: address?.county,
          identifier: address?.identifier,
          zipCode: address?.zip_code,
        },
      });
      return;
    }
    if (key === SignatoryRowOptions.DELETE) {
      handleDelete(signatory);
      return;
    }
  };

  const theme = useTheme();
  const params = useParams<{ proposalId?: string }>();

  const signatoriesList = complianceDocuments
    ?.flatMap((item) =>
      signatories?.filter((signatory) =>
        item.templates.some(
          (template: Template) =>
            signatory?.stakeholder_reference_id?.includes(
              template.template_name
            ) && signatory.order_identifier === params?.proposalId
        )
      )
    )
    .filter(
      (value, index, arr) =>
        index === arr.findIndex((t) => t.identifier === value.identifier)
    );

  return (
    complianceDocuments?.length > 0 && (
      <AddSignatoriesListWrap
        theme={theme}
        className="add-signatories-list-wrap"
      >
        <Box theme={theme} className="signatories-list">
          {signatoriesList.map((signatory: SignatoryResponse, key) => (
            <Box
              key={key}
              theme={theme}
              className={clsx("signatory-list-item", {
                "item-without-action": hideActionButtons,
              })}
            >
              <Grid theme={theme} container spacing={2} alignItems={"center"}>
                <Grid theme={theme} item xs={12} md={5} lg={4} xl={3}>
                  <Typography
                    theme={theme}
                    component={"span"}
                    variant="body2"
                    display={"block"}
                  >
                    {signatory.name}
                  </Typography>
                </Grid>
                <Grid theme={theme} item xs={12} md={7} lg={8} xl={9}>
                  <Box theme={theme} className="name-with-action">
                    <Typography
                      theme={theme}
                      component={"span"}
                      variant="body2"
                      display={"block"}
                    >
                      {signatory?.stakeholder_reference_id
                        .map((ref) => ref.replace(/\.[^/.]+$/, ""))
                        .join(", ")}
                    </Typography>
                    {!hideActionButtons && (
                      <Box theme={theme} className="action-area">
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: "View/Edit",
                              optionkey: "view-edit",
                              optionValue: signatory,
                            },
                            {
                              optionText: "Delete",
                              optionkey: "delete",
                              optionValue: signatory.identifier,
                            },
                          ]}
                          handleOptionClick={handleOptionClick}
                          render={(cb) => (
                            <Button
                              defaultBtn
                              theme={theme}
                              iconText={<Icon name="MoreIcon" />}
                              onClick={cb}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
          {!hideActionButtons && (
            <Box theme={theme} className="signatory-list-action" mt={2}>
              <Button
                theme={theme}
                secondary
                fullWidth
                text={"Add Signatories"}
                type="button"
                disabled={isGeneratedDocument}
                onClick={() => openSignatoryDialog()}
              />
            </Box>
          )}
        </Box>

        <AddSignatoriesDialog
          open={signatoryDialogOpened}
          onClose={closeSignatoryDialog}
          data={signatoryDialogData}
          referenceId={uniqueTemplateNames}
        />
      </AddSignatoriesListWrap>
    )
  );
};

export default SignatoriesList;
