import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, Typography } from "@ntpkunity/controls";
import { useHasPermissions } from "@ntpkunity/controls-ums";
import CelebrationIcon from "../../../public/assets/images/celebration-icon.gif";
import { BannerWrap } from "./banner.style";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
import { getDownloadDocumentPayload, isDealerRole } from "@helpers/utils";
import {
  QuotationForm,
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useDownloadDocs } from "@hooks/mutations";
import { DownloadDocument, Events, UserPermissions } from "@helpers/enum";
import { useSnackbarContext } from "@contexts/snackbar";
import {
  useGetAllLenders,
  useGetAssetCategories,
  useGetAssetConditions,
  useGetAssetSubTypes,
  useGetAssetTypes,
  useGetClientTypes,
  useGetCompanyByTenantId,
  useGetFinanceTypes,
  useGetProductTypes,
} from "@hooks/queries";
import { SNACKBAR_MESSAGES } from "@helpers/const";

export const Banner = () => {
  const theme = useTheme();
  const { watch, getValues: getProposalValues } =
    useFormContext<TProposalForm>();
  const { getValues: getFinanceDetailsValues } =
    useQuotationFormContext<QuotationForm>();
  const { data: calculations } = useCalculationsContext();
  const { mutateAsync: downloadDocument } = useDownloadDocs();
  const { setSnackbar } = useSnackbarContext();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const {
    isLoading: clientTypesLoading,
    isError: isErrorInClientTypes,
    data: clientTypes,
  } = useGetClientTypes();
  const {
    isLoading: financeTypesLoading,
    isError: isErrorInFinanceTypes,
    data: financeTypes,
  } = useGetFinanceTypes();
  const { data: productTypes } = useGetProductTypes();
  const { data: assetSubTypes } = useGetAssetSubTypes();
  const { data: assetCategories } = useGetAssetCategories();
  const { data: assetTypes } = useGetAssetTypes();
  const { data: lenders } = useGetAllLenders();
  const { data: assetConditions } = useGetAssetConditions();

  const disableDownload =
    clientTypesLoading ||
    isErrorInClientTypes ||
    financeTypesLoading ||
    isErrorInFinanceTypes;

  const referenceNumber = watch("identifier");
  const name = watch("proposalDetails.name");

  const handleDownloadProposal = async () => {
    try {
      const dataPlaceholders = getDownloadDocumentPayload({
        documentType: DownloadDocument.PROPOSAL,
        proposalValues: getProposalValues(),
        financialCalculations: calculations,
        financialValues: getFinanceDetailsValues(),
        setups: {
          financeTypes: financeTypes,
          productTypes: productTypes,
          clientTypes: clientTypes,
          assetSubTypes: assetSubTypes,
          assetCategories: assetCategories,
          assetConditions: assetConditions,
          assetTypes: assetTypes,
          lenders: lenders,
        },
        companyId: companyInfo.id,
      });

      await downloadDocument({
        payload: dataPlaceholders,
        eventName: Events.DOWNLOAD_PROPOSAL,
      });
    } catch (err) {
      setSnackbar(
        {
          open: true,
          message: SNACKBAR_MESSAGES.ERROR_WHILE_DOWNLOADING_PROPOSAL,
          variant: "error",
        },
        5000
      );
    }
  };

  return (
    <Stack paddingXs={3} paddingMd={5}>
      <BannerWrap theme={theme} className="banner-wrap">
        <img src={CelebrationIcon} alt="CelebrationIcon" />
        <Box theme={theme}>
          <Typography theme={theme} component="h2" variant="h2">
            Congratulations
          </Typography>
          <Typography
            mt={2}
            theme={theme}
            component="span"
            variant="body2"
            className="text-muted"
            display={"block"}
          >
            Your proposal <span className="fw-medium text-dark">{name}</span>{" "}
            reference number{" "}
            <span className="fw-medium text-dark">{referenceNumber}</span> has
            been submitted to the {isDealerRole() ? "broker" : "lender"} for the
            review. Our representative will be in touch shortly to update you on
            progress.
          </Typography>
          <Box theme={theme} className="actions" mt={3}>
            <Button
              theme={theme}
              primary
              text="Download Proposal"
              disabled={disableDownload}
              onClick={handleDownloadProposal}
            />
          </Box>
        </Box>
      </BannerWrap>
    </Stack>
  );
};
