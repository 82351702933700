import { IThemeBuilder } from "@_types";
import { getThemeBuilderByEmail } from "@services/index.service";
import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";

const useGetThemebyEmail = (userEmail: string) => {
  return useQuery<IThemeBuilder>({
    queryKey: [QUERY_KEYS.GET_THEME_BUILDER, userEmail],
    queryFn: () => getThemeBuilderByEmail(userEmail),
    enabled: !!userEmail,
  });
};

export default useGetThemebyEmail;
