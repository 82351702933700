import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import useGetCompanyByTenantId from "./useGetCompanyProfile";
import { DocumentTypeSetupRes } from "@_types/setups";
import { getDocumentTypes } from "@services/index.service";

const useGetDocumentTypes = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();

  return useQuery<number, unknown, DocumentTypeSetupRes>({
    queryKey: [QUERY_KEYS.GET_DOCUMENT_TYPES, companyInfo?.id],
    queryFn: () => getDocumentTypes(companyInfo?.id),
    enabled: !!companyInfo?.id,
  });
};

export default useGetDocumentTypes;
