import { AuthLayout, SignInForm } from "@components";
import { useTheme } from "@mui/material";
import LoginSideBarImage from "../../../public/assets/images/NatWest-login-sidebar-img.svg";
import AppLogo from "../../../public/assets/images/NatWest-logo-full.svg";

const SignInPage = () => {
  const theme = useTheme();

  return (
    <AuthLayout logoImage={AppLogo} sideBarImage={LoginSideBarImage}>
      <SignInForm title={"Link"} />
    </AuthLayout>
  );
};

export default SignInPage;
