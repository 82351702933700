import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { searchCompanies } from "@services/hubex.service";
import { CompaniesByNameResponse, CompanySearchReqParams } from "@_types";

const useSearchCompanies = (
  xServiceProvider: string,
  params: CompanySearchReqParams,
  onSuccess: (data: CompaniesByNameResponse[]) => void
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.SEARCH_COMPANIES, params],
    queryFn: () => searchCompanies(xServiceProvider, params),
    enabled:
      !!xServiceProvider &&
      (!!params.company_name || !!params.registration_number),
    onSuccess: onSuccess,
    cacheTime: 0,
  });
};

export default useSearchCompanies;
